


import { Component, Vue, Mixins } from "vue-property-decorator";
import Axios from "axios";
import { BASE_API_URL } from "@/config";


@Component
export default class CustomerRetentionComponent extends Vue {
  /**
   * This holds the retention profile information related to the broker.
   */
  public retentionProfile: any = {
    enrollmentStatus: "Fetching..." // This is just to render blank ui until the detail is fetched from server
  };

  /**
   * To check if the enrollment is completed.
   */
  public enrollmentComplete = false;

  /**
   * This marks whether the enrollment page can be rendered on ui.
   */
  public renderEnrollmentPage = false;

  /**
   * To enable and disable the ui loader
   */
  public showLoader = false;

  /**
   * List of all customers.
   */
  public customers = [];

  /**
   * This holds the information of the selected file
   */
  public selectedFile: any = {};

  /**
   * This will toggle the upload ui and customers listing
   */
  public uploadNewDb = false;

  /**
   * The table pagination state data.
   * This will change at every page switch
   */
  public pageConfig = {
    pageNumber: 1,
    pageLimit: 10,
    loadText: "Load more"
  };

  /**
   * The count of all the customers in the database
   */
  public totalCustomers = 0;

  /**
   * This holds the information of a customer
   * Genrally used while showing information in modal
   */
  public customerInfo: any = {};

  /**
   * This holds the value of search
   */
  public searchKey: string = null;

  /**
   * The upload button status text
   */
  public uploadButtonText = "Submit";

  /**
   * This is the initial call for enrollment. Once the button is clicked,
   * we will get the basic information of the broker and post it the source.
   */
  public async enrollForRetention() {
    try {
      this.$store.state.wemloLoader = true;
      let response = await Axios.post(
        BASE_API_URL + "customer-retention/enrollForRetention",
        { brokerId: this.$brokerId });
      this.$snotify.success(response.data.message);
      this.getRetentionProfile();
      this.$store.state.wemloLoader = false;
    } catch (error) {
      this.$store.state.wemloLoader = false;
    }
  }

  /**
   * Check whether the client has enrolled to stikkum.
   */
  public async checkEnrollmentStatus() {
    try {
      let response = await Axios.get(
        BASE_API_URL + "customer-retention/getEnrollmentStatus",
        { params: { brokerId: this.$brokerId } }
      );
      this.retentionProfile.enrollmentComplete = response.data.enrolled;
      this.retentionProfile = { ...this.retentionProfile };
    } catch (error) {}
  }

  /**
   * Fetch the retention profile information of
   * the broker inorder to manage what items should be
   * rendered on ui
   */
  public async getRetentionProfile() {
    try {
      this.$store.state.wemloLoader = true;
      let response = await Axios.get(
        BASE_API_URL + "customer-retention/getRetentionProfile",
        { params: { brokerId: this.$brokerId } }
      );
      this.retentionProfile = response.data;

      /**
       * Call the api to list all the customers only when
       * the enrollment status is Approved.
       */
      if (this.retentionProfile.enrollmentStatus == "Approved") {
        this.getAllCustomers();
      }

      /**
       * Called the api to check enrollment status if the enrollment isn't
       * complete but the broker has enabled retention service.
       */
      if (
        !this.retentionProfile.enrollmentComplete &&
        this.retentionProfile.retentionEnabled
      ) {
        this.checkEnrollmentStatus();
      }
      this.$store.state.wemloLoader = false;
    } catch (error) {
      this.$store.state.wemloLoader = false;
    }
  }

  /**
   * Method to list all the customers of this broker
   */
  public async getAllCustomers(option: any = {}) {
    try {
      let response = await Axios.get(
        BASE_API_URL + "customer-retention/listCustomers",
        {
          params: {
            brokerId: this.$brokerId,
            searchKey: option.searchKey,
            pageLimit: this.pageConfig.pageLimit,
            pageNumber: this.pageConfig.pageNumber
          }
        }
      );

      /**
       * After deleting or marking self pull,
       * the data refetched and pushed in array need to be reset instead of
       * being pushed.
       */
      option.reset
        ? (this.customers = response.data.data)
        : this.customers.push(...response.data.data);

      this.totalCustomers = response.data.totalCount;
      this.pageConfig.loadText = "Load more";

      /**
       * This condition is to handle the upload ui
       * where we are not letting it render incase the data
       * is searched and no results found
       */
      if (!this.searchKey && this.customers.length == 0)
        this.uploadNewDb = true;
      this.$store.state.wemloLoader = false;
    } catch (error) {
      this.$store.state.wemloLoader = false;
    }
  }

  public loadMoreData() {
    this.pageConfig.pageNumber++;
    this.pageConfig.loadText = "Loading...";
    this.getAllCustomers();
  }

  public async uploadFile() {
    try {
      this.$store.state.wemloLoader = true;
      if (this.uploadButtonText == "Submitting...") return;
      /**
       * Validate if the file is selected
       */
      if (!this.selectedFile.name) {
        this.$snotify.error("You must select a database file to upload.");
        return;
      }
      /**
       * Initialize form data
       */
      let formData = new FormData();
      formData.append("retention_db", this.selectedFile);

      this.uploadButtonText = "Submitting...";
      /**
       * Post it to server
       */
      let response = await Axios.post(
        BASE_API_URL + "customer-retention/uploadClientDatabase",
        formData,
        { params: { brokerId: this.$brokerId } }
      );
      this.uploadNewDb = false;
      this.selectedFile = {};
      this.uploadButtonText = "Submit";
      /**
       * Fetch all the customers again.
       */
      this.getAllCustomers();
    } catch (error) {
      this.$store.state.wemloLoader = false;
      this.uploadButtonText = "Submit";
      if (!error.response) return;
      if (error.response.status == 400) {
        this.$snotify.error("Please upload a valid borrowers database.");
      } else {
        this.$snotify.error(error.response.statusText);
      }
    }
  }

  /**
   * To show and hide the upload ui
   */
  public toggleUploadButton() {
    this.uploadNewDb = !this.uploadNewDb;
    this.selectedFile = {};
  }

  /**
   * This is for marking self pull against a customer.
   */
  public async markSelfPulled(customerId) {
    try {
      this.$store.state.wemloLoader = true;
      let response = await Axios.post(
        BASE_API_URL + "customer-retention/markSelfPulled",
        {},
        {
          params: { brokerId: this.$brokerId, customerId: customerId }
        }
      );

      this.$snotify.success(response.data.message);
      await this.getAllCustomers({ reset: true });
      this.customerInfo = this.customers.find(c => c._id == customerId);
      this.$modal.hide("confirmSelfPullActivation");
      this.$store.state.wemloLoader = false;
    } catch (error) {
      this.$store.state.wemloLoader = false;
    }
  }

  public confirmSelfPull(id) {
    this.customerInfo._id = id;
    this.$modal.show("confirmSelfPullActivation");
  }

  /**
   * Poast the customer id to server for deleting
   */
  public async deleteCustomer(customerId) {
    try {
      this.$store.state.wemloLoader = true;
      let response = await Axios.delete(
        BASE_API_URL + "customer-retention/deleteCustomer",
        {
          params: { brokerId: this.$brokerId, customerId: customerId }
        }
      );
      this.$snotify.success(response.data.message);
      this.$modal.hide("confirmDelete");
      this.pageConfig.pageNumber = 1;
      this.customers = [];
      this.getAllCustomers({ reset: true });
      this.$store.state.wemloLoader = false;
    } catch (error) {
      this.$store.state.wemloLoader = false;
    }
  }

  public viewCustomerInfo(customer) {
    this.$modal.show("informationPopup");
    this.customerInfo = { ...customer };
  }

  public customersSearch(event: any) {
    this.searchKey = event.target.value;
    /**
     * Trim extra spaces
     */
    this.searchKey = this.searchKey.replace(/\s+/g, " ").trim();

    /**
     * Handle ui if the search value is reset
     */
    if (!this.searchKey) {
      this.getAllCustomers({ reset: true });
      return;
    }

    /**
     * Allow the request to be posted only if
     * the search key has a character length of 1
     */
    if (this.searchKey.length < 1) return;

    /**
     * Make the api call for search
     */
    this.getAllCustomers({ reset: true, searchKey: this.searchKey });
  }

  public confirmDelete(id) {
    this.$modal.show("confirmDelete");
    this.customerInfo._id = id;
  }
  mounted() {
    this.getRetentionProfile();
  }
}
